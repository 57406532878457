import { registerLocaleData } from "@angular/common";
import localeEs from "@angular/common/locales/es-MX";
import { Compiler, CompilerFactory, COMPILER_OPTIONS, NgModule } from "@angular/core";
import { JitCompilerFactory } from "@angular/platform-browser-dynamic";
import { DynamicComponent } from "./dynamic.component";
import { BrowserModule } from '@angular/platform-browser';

registerLocaleData(localeEs);

export function createCompiler(compilerFactory: CompilerFactory) {
  return compilerFactory.createCompiler();
}

@NgModule({
  imports: [
    BrowserModule
  ],
  exports: [    
  ],
  declarations: [DynamicComponent],
  providers: [
    { provide: COMPILER_OPTIONS, useValue: {}, multi: true },
    { provide: CompilerFactory, useClass: JitCompilerFactory, deps: [COMPILER_OPTIONS] },
    { provide: Compiler, useFactory: createCompiler, deps: [CompilerFactory] }
  ],
})
export class DynamicModule { 
    constructor(){}
}