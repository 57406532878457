import { Compiler, Component, ComponentRef, ModuleWithComponentFactories, NgModule, OnInit, Sanitizer, ViewChild, ViewContainerRef } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { DynamicComponent } from './dynamic.component';
import { numberToText, requestImageDirective, unscape } from '../utilities/funciones';
import { FormatosApi } from '../servicios/formatos-api.service';
import { DynamicModule } from './dynamic.module';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: [ './dashboard.component.css' ]
})
export class DashboardComponent implements OnInit {
  @ViewChild('dynamic', {read: ViewContainerRef, static: true}) private viewRef: ViewContainerRef;

  public uuid : string = "";
  public templateid: number = 16;
  private template: any;
  private model: any;
  private loadableImages : any[] = [];
  public images : any[] = [];

  constructor(private compiler: Compiler, private route: ActivatedRoute, public sanitizer: Sanitizer, private services : FormatosApi) {

  }

  async ngOnInit() {
    this.route.queryParams.subscribe(async params => {
     
      this.uuid = params['UUID'];
      this.templateid = params['templateid'];
      
      if(this.uuid && this.templateid)
        await this.LoadComponent();

    });
  }

  public async LoadComponent(): Promise<void> {
    try {
      const startGetData = new Date();
      const req = await this.LoadData();
      const endGetData = new Date();
      const executionTimeGetData = endGetData.getTime() - startGetData.getTime();
      console.log(`Get component data (UUID: ${this.uuid} T: ${this.templateid}): ${executionTimeGetData}ms`);
    
      if (req) {
        const startPrepData = new Date();
        this.template = req[0][0].archivo.content;
        this.model = JSON.parse(req[1].customDataObject);
        this.template = this.ejecutarFunciones(this.template, this.model);

        const uniqueImagesIds = Array.from(new Set(this.loadableImages));

        let requestImages: Promise<any>[] = [];

        uniqueImagesIds.forEach(id => {
          requestImages.push(this.services.getLogo(id));
        });

        const res = await Promise.all(requestImages);
        
        this.images = res.map(image => ({
          id: image[0].id,
          content: "data:image/png;base64," + (image[0].archivo.content)
        }));

        const endPrepData = new Date();
        const executionTimePrepData = endPrepData.getTime() - startPrepData.getTime();
        console.log(`Prepare component data (UUID: ${this.uuid} T: ${this.templateid}): ${executionTimePrepData}ms`);

        const startTime = new Date();
        this.generarComponente();
        const endTime = new Date();
        const executionTime = endTime.getTime() - startTime.getTime();
        console.log(`Component generation time (UUID: ${this.uuid} T: ${this.templateid}): ${executionTime}ms`);
      }
    } catch (error) {
     console.error(error)
    }
  }
  
  // Genera un componente dinámicamente a partir de una plantilla dada
  private generarComponente(): void{   

    this.compiler.clearCache(); 
    this.viewRef.clear();

    const componentType = Component({ template: this.template })(DynamicComponent)
    const decoratedNgModule = NgModule({ declarations: [componentType], imports: [BrowserModule], providers:[]})(DynamicModule);      
    const module: ModuleWithComponentFactories<any> = this.compiler.compileModuleAndAllComponentsSync(decoratedNgModule);

    let factory: any = module.componentFactories.find((comp: any) =>
        comp.componentType === componentType,
    );

   const new_component : ComponentRef<DynamicComponent> = this.viewRef.createComponent(factory);

   new_component.instance.data = this.model;
   new_component.instance.sanitizer = this.sanitizer;
   new_component.instance.imagenes = this.images;
   new_component.instance.ngAfterViewInit = () => {};

  } 
  
  private async LoadData(): Promise<any[]> {
    return await Promise.all([this.services.getTemplate(this.templateid), this.services.getModel(this.uuid)]);
  }

  private ejecutarFunciones(template: string, model: any): string {
    template = unscape(template);
    template = numberToText(template);
    const { t, l } = requestImageDirective(template, model);    
    template = t;
    this.loadableImages = l;
    return template;
  }

}
