export function removeCharacters(template) {
    let regex = /(\\r\\n)/
    
    while(true) {
      let match = regex.exec(template);  
      if(!match)
        return template;
      let substring = match[0]
      template = template.replace(substring, '');
    } 
}

export function unscape(template) {
    let regex = /\r|\n/    
    while(true) {
      let match = regex.exec(template);  
      if(!match)
        return template;
      let substring = match[0]
      template = template.replace(substring, '');
    } 
}

function htmlDecode(input) {
    var doc = new DOMParser().parseFromString(input, "text/html");
    return doc.documentElement.textContent;
  }

// Herramienta adaptador para convertir número a texto 
export function numberToText(template) {
  let regex = /{{.+?}}/g
  let matches: string [] = [];
  while(true) {
    let match = regex.exec(template);

    if(!match)
      break;
    
    if(match[0].includes('numberToText'))
      matches.push(match[0]);
  }
  matches.forEach(element => {
    let pipeValueRegex = /[^{{].+?[\|]/;
    let pipeParametersRegex = /numberToText.+?}}/;
    let valueSubstr = pipeValueRegex.exec(element)[0].replace('|','');
    let parametersSubstr = pipeParametersRegex.exec(element)[0].replace("}}",'')
    let params = parametersSubstr.split(':')
    let replaceFunction = `{{ numberToText(${valueSubstr}, ${params[1]}, ${params[2]}, ${params[3]}) }}`;
    template = template.replace(element, replaceFunction);
  });
  
  return template
}


// Herramienta adaptador para sustituir
export function requestImageDirective(template, data) {
    let regex = /\[srcId\]=".+?"/
    template = template.replace("requestImage", "");  
    let loadableImages = [];
    while(true) {
      let match = regex.exec(template);
  
      if(!match)
        return {
          t: template,
          l: loadableImages
        }
          
      let substring = match[0]
      let valueRegex = /\".+?\"/;
      let temp = valueRegex.exec(substring)[0]
      let valueSubstr = temp.replace('\"','').replace('\"','');
      let replaceFunction = ` [src]="findImage(${valueSubstr})"`;

      loadableImages.push(eval(valueSubstr))
      template = template.replace(substring, replaceFunction);
    } 

}
