import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class FormatosApi {
  //private endpoint: string = "https://generador-documentos-ssr.azurewebsites.net/";
  private endpoint: string = "https://generadordocumentosssr.servicios-lis.com.mx/";
  //private endpoint: string = "http://localhost:4000/";
  //private endpoint: string = "https://formatoslis.servicios-lis.com.mx/";

  constructor(private http: HttpClient) {

  }

  public headers = new HttpHeaders({
    'Content-Type': 'application/json; charset=utf-8',
    'Accept': 'application/json, text/javascript, /;',
});

      /**
     * Realiza una metodo GET del web service
     * @param serviceName Nombre del servicio web
     * @param funcName Metodo de la peticion
     */
      public get(serviceName: string, funcName: string): Promise<any> {
        return this.http.get(this.endpoint + serviceName + '/' + funcName, { headers: this.headers })
        .toPromise()
        .catch(error => {
          // Handle error here
          throw error; // Optionally rethrow the error
        });
      }

      
  
    public getTemplate(templateid: any): Promise<any> {
        return this.get('api/Template', `GetTemplate?Id=${templateid}&Descargar=${true}`)
    }

    public getModel(uuid : any): Promise<any> {
        return this.get('api/Documento', `ObtenerDocumentoPdf?objectId=${uuid}`)
    }

    public getLogo(id?: number): Promise<any> {
        return this.get('api/Logo', `GetLogo?Id=${id}&Descargar=${true}`)
    }

}