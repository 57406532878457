import { Pipe, PipeTransform } from '@angular/core';
/*
 * Convierte un numero a texto en Español
 * Toma un numero decimal.
 * Usage:
 *   valor | numberToText: : currency: customCent: centFormat
 *  
 *  valor: number  
 *      Example: 1145.09
 *          
 *  currency: string
 *      Example: 'MXN'
 * 
 *  customCent: string
 *      Example: 'Céntimos'
 *  
 *  centFormat: string
 *      Example: 'numeric'
 * 
 * 
 * Example:
 *   {{ 1145.09 | numberToText:'MXN':'':'' }}
 *   Formatea a: MIL CIENTO CUARENTA Y CINCO PESOS CON NUEVE CENTAVOS
*/

var currencies = [
    { currency:'MXN', singular:'PESO', plural:'PESOS', centSingular: 'CENTAVO', centPlural:'CENTAVOS' },
    { currency:'USD', singular:'DÓLAR', plural:'DOLARES', centSingular: 'CENTAVO', centPlural:'CENTAVOS' },
    { currency:'EUR', singular:'EURO', plural:'EUROS', centSingular: 'CÉNTIMO', centPlural:'CÉNTIMOS' },
    { currency:'GBP', singular:'LIBRA ESTARLINA', plural:'LIBRAS ESTARLINAS', centSingular: 'PENIQUE', centPlural:'PENIQUES' },

]


@Pipe({name: 'numberToText'})
export class NumberToText implements PipeTransform {
  transform(value: number, currency: string, customCent: string, centFormat: string): string {
    return numeroALetras(value, currency, customCent, centFormat);
  }
}

function Unidades(num){

  switch(num)
  {
      case 1: return 'UN';
      case 2: return 'DOS';
      case 3: return 'TRES';
      case 4: return 'CUATRO';
      case 5: return 'CINCO';
      case 6: return 'SEIS';
      case 7: return 'SIETE';
      case 8: return 'OCHO';
      case 9: return 'NUEVE';
  }

  return '';
}//Unidades()

function Decenas(num){

  let decena = Math.floor(num/10);
  let unidad = num - (decena * 10);

  switch(decena)
  {
      case 1:
          switch(unidad)
          {
              case 0: return 'DIEZ';
              case 1: return 'ONCE';
              case 2: return 'DOCE';
              case 3: return 'TRECE';
              case 4: return 'CATORCE';
              case 5: return 'QUINCE';
              default: return 'DIECI' + Unidades(unidad);
          }
      case 2:
          switch(unidad)
          {
              case 0: return 'VEINTE';
              default: return 'VEINTI' + Unidades(unidad);
          }
      case 3: return DecenasY('TREINTA', unidad);
      case 4: return DecenasY('CUARENTA', unidad);
      case 5: return DecenasY('CINCUENTA', unidad);
      case 6: return DecenasY('SESENTA', unidad);
      case 7: return DecenasY('SETENTA', unidad);
      case 8: return DecenasY('OCHENTA', unidad);
      case 9: return DecenasY('NOVENTA', unidad);
      case 0: return Unidades(unidad);
  }
}//Unidades()

function DecenasY(strSin, numUnidades) {
  if (numUnidades > 0)
      return strSin + ' Y ' + Unidades(numUnidades)

  return strSin;
}//DecenasY()

function Centenas(num) {
  let centenas = Math.floor(num / 100);
  let decenas = num - (centenas * 100);

  switch(centenas)
  {
      case 1:
          if (decenas > 0)
              return 'CIENTO ' + Decenas(decenas);
          return 'CIEN';
      case 2: return 'DOSCIENTOS ' + Decenas(decenas);
      case 3: return 'TRESCIENTOS ' + Decenas(decenas);
      case 4: return 'CUATROCIENTOS ' + Decenas(decenas);
      case 5: return 'QUINIENTOS ' + Decenas(decenas);
      case 6: return 'SEISCIENTOS ' + Decenas(decenas);
      case 7: return 'SETECIENTOS ' + Decenas(decenas);
      case 8: return 'OCHOCIENTOS ' + Decenas(decenas);
      case 9: return 'NOVECIENTOS ' + Decenas(decenas);
  }

  return Decenas(decenas);
}//Centenas()

function Seccion(num, divisor, strSingular, strPlural) {
  let cientos = Math.floor(num / divisor)
  let resto = num - (cientos * divisor)

  let letras = '';

  if (cientos > 0)
      if (cientos > 1)
          letras = Centenas(cientos) + ' ' + strPlural;
      else
          letras = strSingular;

  if (resto > 0)
      letras += '';

  return letras;
}//Seccion()

function Miles(num) {
  let divisor = 1000;
  let cientos = Math.floor(num / divisor)
  let resto = num - (cientos * divisor)

  let strMiles = Seccion(num, divisor, 'UN MIL', 'MIL');
  let strCentenas = Centenas(resto);

  if(strMiles == '')
      return strCentenas;

  return strMiles + ' ' + strCentenas;
}//Miles()

function Millones(num) {
  let divisor = 1000000;
  let cientos = Math.floor(num / divisor)
  let resto = num - (cientos * divisor)

  let strMillones = Seccion(num, divisor, 'UN MILLON', 'MILLONES');
  let strMiles = Miles(resto);

  if(strMillones == '')
      return strMiles;

  return strMillones + ' ' + strMiles;
}//Millones()

function numeroALetras(num, currency, customCent, centFormat) {
  let moneda = currencies.find(c => c.currency == currency) || { currency: currency, singular: currency, plural: currency, centSingular: customCent, centPlural: customCent };
  let data = {
      numero: num,
      enteros: Math.floor(num),
      centavos: (((Math.round(num * 100)) - (Math.floor(num) * 100))),
      letrasCentavos: '',
      letrasMonedaPlural: moneda.plural || '',
      letrasMonedaSingular: moneda.singular || '', 
      letrasMonedaCentavoPlural: moneda.centPlural || currency,
      letrasMonedaCentavoSingular: moneda.centSingular || currency
  };

  if(centFormat=='numeric')
    return formatearCentavos(data);

  return convertirCentavosATexto(data);
};

function convertirCentavosATexto(data) {
    if (data.centavos > 0) {
        let centavos = ''
        if (data.centavos == 1)
            centavos = Millones(data.centavos) + ' ' + data.letrasMonedaCentavoSingular;
        else
            centavos =  Millones(data.centavos) + ' ' + data.letrasMonedaCentavoPlural;
        data.letrasCentavos = 'CON ' + centavos
    };
  
    if(data.enteros == 0)
        return 'CERO ' + data.letrasMonedaPlural + ' ' + data.letrasCentavos;
    if (data.enteros == 1)
        return Millones(data.enteros) + ' ' + data.letrasMonedaSingular + ' ' + data.letrasCentavos;
    else
        return Millones(data.enteros) + ' ' + data.letrasMonedaPlural + ' ' + data.letrasCentavos;
}

function formatearCentavos(data) {
    if(data.enteros == 0)
        return `CERO  ${data.letrasMonedaPlural} ${ centavosDosDigitos(data.centavos) }/100`;
    if (data.enteros == 1)
        return `${Millones(data.enteros)} ${data.letrasMonedaSingular} CON ${ centavosDosDigitos(data.centavos) }/100`;
    else
        return `${Millones(data.enteros)} ${data.letrasMonedaPlural} CON ${ centavosDosDigitos(data.centavos) }/100`;
}

function centavosDosDigitos(centavos){

    const centavos_numero = Number(centavos);
    return centavos_numero.toLocaleString('es-MX',{minimumIntegerDigits: 2});
}