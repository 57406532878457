import { NgModule } from '@angular/core';
import { BrowserModule }  from '@angular/platform-browser';
import { HttpClientModule }    from '@angular/common/http';

import { AppRoutingModule }     from './app-routing.module';

import { AppComponent }         from './app.component';
import { DashboardComponent }   from './dashboard/dashboard.component';

import { FormatosApi} from './servicios/formatos-api.service'
import { DynamicModule } from './dashboard/dynamic.module';
import { NumberToText } from './utilities/numbertotext.pipe';
import { RequestImageDirective } from './utilities/request-image.directive';



@NgModule({
  imports: [
    BrowserModule.withServerTransition({ appId: 'generador-ssr' }),
    AppRoutingModule,
    HttpClientModule,
    DynamicModule

  ],
  declarations: [
    AppComponent,
    DashboardComponent,
    NumberToText,
    RequestImageDirective
  ],
  providers:[FormatosApi],
  bootstrap: [ AppComponent ]
})
export class AppModule {
  constructor() {
  }
}

