
import { Component, NgModule } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { NumberToText } from '../utilities/numbertotext.pipe';

@Component({
  selector: 'app-dynamic',
  template: '',
  styles: [ `
    :host {
      display: block;
      background-color: #0ff;
      padding: 12px;
      border-radius: 8px
    }
  ` ]
})
export class DynamicComponent {
  data: any;
  time = new Date();
  public imagenes = [];
  public sanitizer;

  constructor(){}

  public sanitizeImageUrl = (imageUrl: string): SafeUrl => this.sanitizer.bypassSecurityTrustUrl(imageUrl);


  ngAfterViewInit() {
  }


  //Usa un pipe para convertir numero a texto
  numberToText(value, currency='MXN', cent='', mode='' ) {
    return new NumberToText().transform(value, currency, cent, mode)
  }

  
  findImage(id) {
    let img = this.imagenes.find(img => img.id == id)
    if(img)
      return this.sanitizeImageUrl(img.content);
    else 
      return this.sanitizeImageUrl("")
  }

}

@NgModule()
export class DynamicTemplateModule {

}