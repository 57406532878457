// request-image.directive
/*  Obtiene una imagen desde un web service a partir de un ID y la asigna a una etiqueta IMG.
        Uso:
            Llamar mediante selector 'requestImage' y especificar un id de imagen usando [srcId]="id"
        Ejemplo:
            <img requestImage [srcId]="2"/>

        -El ID debe estar registrado en base de datos y debe apuntar a un document id en Mongo DB
        -El parámetro 'Descargar' debe estar activado para habilitar la descarga del archivo de imagen en base64.
*/


import {  Directive, ElementRef, Input } from "@angular/core";

@Directive({
    selector: '[requestImage]'
  })
  export class RequestImageDirective {
  
    el: ElementRef
    @Input() srcId : number;

    private imgWidth: any;
    private imgHeight: any;
  
    constructor(_el:ElementRef) {
      this.el = _el;
    }
  
    async ngOnInit() {
        // let image = await this.obtenerImagen(this.srcId);
        // this.el.nativeElement.src = `data:image/png;base64,${image.archivo.content}`;
    }

    //Obtener Logo
    async obtenerImagen(id: number): Promise<any> {
        // let descargar: boolean = true;
        // return new Promise((resolve, reject)=> {
        //     this.generalService.get('api/Logo', `GetLogo?Id=${id}&Descargar=${descargar}`).subscribe(
        //         result => {resolve(result[0])},
        //         error => reject(error));
        // });
    }
  }